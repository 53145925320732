import React, {
  FC,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import Swal from "sweetalert2";
import { useStakedNfts } from "./hooks/useStakedNfts";
import { useUnstakedNfts } from "./hooks/useUnstakedNfts";

import { PublicKey } from "@solana/web3.js";
import {
  createStakeTokenTransaction,
  createUnstakeTokenTransaction,
  createPayAndUnstakeTokenTransaction,
  createWithdrawRewardTransaction,
  createUpdateTokenTransaction,
  getRewardCalculator,
  calculateRewards,
  StakedData,
  UnstakedData,
  RewardCalculator,
  getData,
} from "./util/staking";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";
import {
  Connection,
  Transaction,
  clusterApiUrl,
  SystemProgram,
  TransactionInstruction,
  LAMPORTS_PER_SOL,
  Keypair,
  sendAndConfirmTransaction,
} from "@solana/web3.js";
import {
  Navbar,
  Container,
  Nav,
  Row,
  Col,
  Card,
  Button,
  Spinner,
} from "react-bootstrap";
import logo from "../src/assest/logo.png";
import brand from "../src/assest/brand.gif";
import menu_discord from "../src/assest/menu-discord.png";
import menu_twitter from "../src/assest/menu-twitter.png";
import stack_img from "../src/assest/stake.png";
import earn from "../src/assest/earn-money.png";
import { Loader } from "./loader";
import moment from "moment";

let rewardsCount: any = 0;
type DisplayEncoding = "utf8" | "hex";
type PhantomEvent = "disconnect" | "connect" | "accountChanged";
type PhantomRequestMethod =
  | "connect"
  | "disconnect"
  | "signTransaction"
  | "signAllTransactions"
  | "signMessage";

interface ConnectOpts {
  onlyIfTrusted: boolean;
}
interface PhantomProvider {
  publicKey: PublicKey;
  isConnected: boolean | null;
  signTransaction: (transaction: Transaction) => Promise<Transaction>;
  signAndSendTransaction: (transaction: Transaction) => Promise<any>;
  signAllTransactions: (transactions: Transaction[]) => Promise<Transaction[]>;
  signMessage: (
    message: Uint8Array | string,
    display?: DisplayEncoding
  ) => Promise<any>;
  connect: (opts?: Partial<ConnectOpts>) => Promise<{ publicKey: PublicKey }>;
  disconnect: () => Promise<void>;
  on: (event: PhantomEvent, handler: (args: any) => void) => void;
  request: (method: PhantomRequestMethod, params: any) => Promise<unknown>;
}
const getProvider = (): PhantomProvider | undefined => {
  if ("solana" in window) {
    const anyWindow: any = window;
    const provider = anyWindow.solana;
    if (provider.isPhantom) {
      // console.log("provider:"+ provider)
      return provider;
    }
  }
  // window.open("https://phantom.app/", "_blank");
};

export const Navigation: FC = () => {
  const { connection } = useConnection();
  const { wallet } = useWallet();

  const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);
  const [stakeMsgDisplay, setStakeMsgDisplay] = useState<boolean>(false);
  const [stakeSuccessDisplay, setStakeSuccessDisplay] =
    useState<boolean>(false);
  const [transfailureStatus, setTransfailureStatus] = useState<boolean>(false);
  const stakedNfts = useStakedNfts(refreshHandle);
  const unstakedNfts = useUnstakedNfts(refreshHandle);
  const [stake, setStake] = useState<boolean>(false);
  const [unStake, setUnStake] = useState<boolean>(false);
  const [withdraw, setwithdraw] = useState<boolean>(false);
  const [totalrewardCount, setTotalrewardCount] = useState(0);
  const [rewardCalculator, setRewardCalculator] =
    useState<RewardCalculator | null>(null);

  const [rewardDate, setRewardDate] = useState<Date>(new Date());
  const [isDataRefreshed, setisDataRefreshed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInnerLoading, setisInnerLoading] = useState(true);

  const provider = getProvider();

  useEffect(() => {
    setisInnerLoading(false);
  }, [refreshHandle]);

  useEffect(() => {
    if (!rewardCalculator) return;
    let { rewardPeriod } = rewardCalculator;
    let interval = Number.MAX_SAFE_INTEGER;
    if (rewardPeriod < BigInt(Number.MAX_SAFE_INTEGER))
      interval = parseInt(rewardPeriod.toString()) * 1000;

    let id = setInterval(() => setRewardDate(new Date()), interval);

    return () => clearInterval(id);
  }, [rewardCalculator]);
  const setStakeMsg = (val: boolean): void => {
    //console.log("display msg staking" +val)
    setStakeMsgDisplay(val);
    // setIsLoading(val)
    if (val) {
      setStakeSuccessDisplay(false);
    }
  };

  const setLoading = (val: boolean): void => {
    //console.log("display msg staking" +val)
    setIsLoading(val);
  };

  const transFailure = (val: boolean): void => {
    if (val) {
      setTransfailureStatus(true);
    } else {
      setTransfailureStatus(false);
    }
  };
  const stakeFlag = (val: boolean): void => {
    // console.log("display msg staking" + val);
    // console.log("staking" + stake);

    setStake(val);
    setUnStake(!val);
    setwithdraw(!val);
    if (!stakeMsgDisplay && val && !transfailureStatus) {
      setStakeSuccessDisplay(true);
      Swal.fire({
        text: "You have Staked Your NFT Successfully!",
        confirmButtonText: "Close",
      });
    } else {
      setStakeSuccessDisplay(false);
    }
  };
  const unStakeFlag = (val: boolean): void => {
    //console.log("display msg staking" +val)

    setStake(!val);
    setwithdraw(!val);
    setUnStake(val);
    if (!stakeMsgDisplay && val && !transfailureStatus) {
      setStakeSuccessDisplay(true);
      Swal.fire({
        text: "You have Successfully Withdrawn Rewards and Unstaked Your NFT!",
        confirmButtonText: "Close",
      });
    } else {
      setStakeSuccessDisplay(false);
    }
  };

  const withDrawRewardFlag = (val: boolean): void => {
    setStake(!val);
    setUnStake(!val);
    setwithdraw(val);
    if (!stakeMsgDisplay && val && !transfailureStatus) {
      setStakeSuccessDisplay(true);
      Swal.fire({
        text: "You Have Successfully Withdrawn Your Rewards!",
        confirmButtonText: "Close",
      });
    } else {
      setStakeSuccessDisplay(false);
    }
  };

  useEffect(() => {
    getRewardCalculator(connection).then((r) => setRewardCalculator(r));
  }, [connection]);


  
  const totalRewards = (count: any): void => {
    rewardsCount = rewardsCount + parseInt(count);
    // console.log("reward", rewardsCount);
  };

  useEffect(() => {
    getData(connection);

    stakedNfts.map((nft, index) => {
      // Swal.fire({
      //   text: "Your 30-day mission to protect the Space ends on  To abort the mission, please pay the 499 $VAULT and abort the mission(Unstake).",
      //   confirmButtonText: "Abort the mission",
      //   customClass: {
      //     confirmButton: 'twitter-share-btn'
      //   },
      //   reverseButtons:true,
      //   showCancelButton: true,
      // }).then((result) => {
      //   /* Read more about isConfirmed, isDenied below */
      //   if (result.isConfirmed) {
      //     console.log("paying");
      //   }
      // });
      // console.log(nft);
      // console.log(nft.mint.toString());
      let { timestamp } = nft;
      let c_d = moment.unix(Number(timestamp));
      let d = moment.duration(moment().diff(c_d));
      if (nft.locking_timestamp !== 0) {
        let c_d = moment.unix(nft.locking_timestamp);
        let d = moment.duration(moment().diff(c_d));
        // console.log(d)
        // nft['art_upgated_locking_period'] = d.asHours();
        nft["art_upgated_locking_period"] = d.asDays();
      } else {
        nft["art_upgated_locking_period"] = 0;
      }
      nft["t"] = d.asHours();
      // nft['t'] = d.asMinutes();

      if (index < stakedNfts.length) {
        totalRewards(
          calculateRewards(nft as StakedData, rewardDate, rewardCalculator)
        );
      }
      // console.log(nft)
    });

    setTotalrewardCount(rewardsCount);
    rewardsCount = 0;
  }, [wallet, stakedNfts]);

  const msToTime = (duration) => {
    var milliseconds: string = String((duration % 1000) / 100);
    var seconds: string = String((duration / 1000) % 60);
    var minutes: string = String((duration / (1000 * 60)) % 60);
    var hours: string = String((duration / (1000 * 60 * 60)) % 24);

    hours = parseInt(hours) < 10 ? "0" + hours : hours;
    minutes = parseInt(minutes) < 10 ? "0" + minutes : minutes;
    seconds = parseInt(seconds) < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  };

  return (
    <>
      {isLoading && <Loader />}
      <Navbar collapseOnSelect expand="lg" bg="transparent" variant="dark">
        <Container className="header-menu-container">
          <Navbar.Brand href="#home">
            <div className="logo">
              <img src={logo}></img>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                href="https://thevault-x.com/#about"
                target="_blank"
                className="headerFont"
              >
                What Is Vault-X?
              </Nav.Link>
              <Nav.Link
                href="https://thevault-x.com/#how"
                target="_blank"
                className="headerFont"
              >
                How It Works
              </Nav.Link>
              <Nav.Link
                href="https://thevault-x.com/#nftutilities"
                target="_blank"
                className="headerFont"
              >
                NFT/Token Utilities
              </Nav.Link>
              <Nav.Link
                href="https://thevault-x.com/#roadmap"
                target="_blank"
                className="headerFont"
              >
                Roadmap
              </Nav.Link>
              <Nav.Link
                href="https://thevault-x.com/#team"
                target="_blank"
                className="headerFont"
              >
                Team
              </Nav.Link>
              <Nav.Link
                href="https://thevault-x.com/#faq"
                target="_blank"
                className="headerFont"
              >
                FAQs
              </Nav.Link>
            </Nav>
            <Nav className="nav-social-container">
              {/* <Nav.Link href="https://lostsols.club/search.php" target="_blank">Rarity Tool</Nav.Link> */}
              {!wallet && (
                <Nav.Link
                  href="https://twitter.com/TheVaultDAO"
                  target="_blank"
                  className="nav-social"
                >
                  <img src={menu_twitter} />
                </Nav.Link>
              )}
              {!wallet && (
                <Nav.Link
                  href="https://discord.gg/CaSVfkCbvc"
                  target="_blank"
                  className="nav-social"
                >
                  <img src={menu_discord} />
                </Nav.Link>
              )}
              {wallet ? (
                <Nav.Item>
                  <WalletDisconnectButton className="mx-auto btn disconnect-btn" />
                </Nav.Item>
              ) : null}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* {!wallet ? (
        <Container className="pb-5">
          <Row className="text-center mt-5">
            <Col>
              <div><img className="brand" src={brand} /></div>
            </Col>
          </Row>
          <Row className="text-center mt-5">
            <Col>
              <div className="headline">Vault-X Staking Pad!</div>
              <div className="sub-heading">Vault-X is a collection of 3333 randomly generated unique NFTs to keep your funds secure while dealing with anonymous people. Our Protection protocol for Web 3.0 safeguards the interests of both the buyer and the seller through a decentralized and autonomous vault contract.</div>
            </Col>
          </Row>
          <Row className="mt-5">

            <Col>
              <WalletMultiButton className="mx-auto btn" />
            </Col>
          </Row>
        </Container>) : null} */}
      {wallet && provider && provider.isConnected ? (
        <Container className="pl-5 pr-5 pb-5">
          {/* <Row className="text-center mt-5">
          <Col>
          <div><img className="brand" src={brand} /></div>
          </Col>
        </Row> */}
          <Row className="text-center mt-5">
            <Col>
              <div className="headline">Vault-X Staking Pad!</div>
              <div className="sub-heading-1">
                Stake your Guard and Earn $VAULT
              </div>
            </Col>
          </Row>
          <div className="sub-container">
            <Row className="text-center mt-5">
              <Col>
                <div className="row countStyle">
                  <div className="column">
                    <Row>
                      <Col md={2}>
                        <img width="60" src={stack_img} />
                      </Col>
                      <Col md={10} className="count-col-right">
                        <div className="text-left">
                          <div>{stakedNfts.length}</div>
                          <div className="count-col-text">
                            Total Number of NFT Staked
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="column">
                    <Row>
                      <Col md={2}>
                        <img width="60" src={earn} />
                      </Col>
                      <Col md={10} className="count-col-right">
                        <div className="text-left">
                          <div>{totalrewardCount}</div>
                          <div className="count-col-text">
                            Total $VAULT Earned
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="sub-heading-1 mt-3 font-w-600">
                  Minimum Staking Period : 3 days
                </div>
                <div className="mt-4 sub-heading info-sub-heading">
                  Click The "Select" Button Under Your NFTs To Earn Rewards!
                </div>
                {refreshHandle ? (
                  <div className="color-white">
                    (It may take up to 30 seconds to load your NFTs once you
                    have connected your wallet)
                  </div>
                ) : null}
              </Col>
            </Row>
            {/* {stakeMsgDisplay ? (
              <Row className="trans">
                <span>Transaction is in Progress. Please wait!!!</span>
              </Row>
            ) : null}
            {stakeSuccessDisplay ? (
              <Row className="trans-success">
                <span>
                  {stake
                    ? "Stake"
                    : unStake
                    ? "Withdraw & Unstake"
                    : withdraw
                    ? "Withdraw"
                    : null}{" "}
                  is Successful!!
                </span>
              </Row>
            ) : null} */}
            <Row className="mt-5 mb-3">
              <Col>
                <div className="c-heading">Your Staked NFTs</div>
              </Col>
            </Row>

            <Row style={{ paddingTop: "12px" }} className="">
              {stakedNfts.map((nft) => (
                <Col xs={12} md={3} sm={12} className="mb-5">
                  <NFT
                    key={nft.json.name}
                    nft={nft}
                    onChange={forceRefresh}
                    staked={true}
                    stakecount={stakedNfts.length}
                    rewardCalculator={rewardCalculator}
                    msg={setStakeMsg}
                    isloading={setLoading}
                    stakeFlag={stakeFlag}
                    unStakeFlag={unStakeFlag}
                    withDrawRewardFlag={withDrawRewardFlag}
                    transfailure={transFailure}
                    totalRewards={totalRewards}
                  />
                </Col>
              ))}
            </Row>

            <Row className="mt-5 mb-3">
              <Col>
                <div className="c-heading">Your Unstaked NFTs</div>
              </Col>
            </Row>
            <Row style={{ paddingTop: "12px" }} className="">
              {isInnerLoading && <div className="inner-loading-spinner"></div>}
              {unstakedNfts.map((nft) => (
                <Col xs={12} md={3} sm={12} className="mb-5">
                  <NFT
                    key={nft.json.name}
                    nft={nft}
                    onChange={forceRefresh}
                    staked={false}
                    stakecount={stakedNfts.length}
                    msg={setStakeMsg}
                    isloading={setLoading}
                    stakeFlag={stakeFlag}
                    unStakeFlag={unStakeFlag}
                    withDrawRewardFlag={withDrawRewardFlag}
                    transfailure={transFailure}
                    totalRewards={totalRewards}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      ) : (
        <Container className="pb-5 full-height">
          <Row className="text-center mt-5">
            <Col>
              <div>
                <img className="brand" src={brand} />
              </div>
            </Col>
          </Row>
          <Row className="text-center mt-5">
            <Col>
              <div className="headline">Vault-X Staking Pad!</div>
              <div className="sub-heading">
                Vault-X is a collection of 3333 randomly generated unique NFTs
                to keep your funds secure while dealing with anonymous people.
                Our Protection protocol for Web 3.0 safeguards the interests of
                both the buyer and the seller through a decentralized and
                autonomous vault contract.
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <WalletMultiButton className="home-sellect-wallet-btn mx-auto btn" />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

const NFT = ({
  nft,
  staked,
  stakecount,
  onChange,
  rewardCalculator = null,
  msg,
  isloading,
  stakeFlag,
  unStakeFlag,
  transfailure,
  withDrawRewardFlag,
  totalRewards,
}: {
  nft: UnstakedData;
  staked: boolean;
  stakecount: any;
  onChange?: () => any;
  rewardCalculator?: RewardCalculator | null;
  msg?: (arg: boolean) => void;
  isloading?: (arg: boolean) => void;
  stakeFlag?: (arg: boolean) => void;
  unStakeFlag?: (arg: boolean) => void;
  withDrawRewardFlag?: (arg: boolean) => void;
  transfailure?: (arg: boolean) => void;
  totalRewards?: (arg: any) => void;
}) => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();

  //const [ transFailure, setTransFailure ] = useState<boolean>(false);
  const [rewardDate, setRewardDate] = useState<Date>(new Date());
  const is_upgradable = 1; //here
  useEffect(() => {
    if (!rewardCalculator) return;
    let { rewardPeriod } = rewardCalculator;

    let interval = Number.MAX_SAFE_INTEGER;
    if (rewardPeriod < BigInt(Number.MAX_SAFE_INTEGER))
      interval = parseInt(rewardPeriod.toString()) * 1000;

    let id = setInterval(() => setRewardDate(new Date()), interval);

    return () => clearInterval(id);
  }, [rewardCalculator]);

  const sendAndConfirmTransaction = useCallback(
    async (transaction, address = "") => {
      console.log(address.toString());
      try {
        if (transfailure) {
          transfailure(false);
        }

        let { blockhash } = await connection.getRecentBlockhash();
        transaction.feePayer = publicKey!;
        transaction.recentBlockhash = blockhash;

        let signature = await sendTransaction(transaction, connection, {
          skipPreflight: false,
        });
        if (msg) msg(true);
        await connection.confirmTransaction(signature, "confirmed");

        console.log(signature);
        if (msg) msg(false);
        if (isloading) isloading(false);
        if (onChange) onChange();

        return signature;
      } catch (err) {
        if (msg) msg(false);
        if (isloading) isloading(false);
        if (stakeFlag) stakeFlag(false);
        if (unStakeFlag) unStakeFlag(false);
        if (withDrawRewardFlag) withDrawRewardFlag(false);
        if (transfailure) {
          transfailure(true);
        }

        if (err.message !== "User rejected the request.") {
          if (err.message.indexOf("custom program error: 0x1065") > -1) {
            console.log("The staking period of art after upgrade is 30 days");
          }
        }
      }
    },
    [connection, publicKey, sendTransaction, onChange]
  );

  const stakeToken = useCallback(
    async (address: PublicKey) => {
      if (!publicKey) return;
      isloading(true);
      // console.log("stake token before transaction:"+ showStakeMsg)
      const res = await sendAndConfirmTransaction(
        await createStakeTokenTransaction(connection, publicKey, address)
      );

      if (stakeFlag && res) {
        stakeFlag(true);
      }

      //  setShowStakeMsg(false);
      //  console.log("stake token after transaction:"+ showStakeMsg)
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  const unstakeToken = useCallback(
    async (
      address: PublicKey,
      daydiff: Number,
      art_upgated_locking_period: Number,
      time
    ) => {
      if (art_upgated_locking_period !== 0 && art_upgated_locking_period < 30) {
        let day = moment.unix(time).add("30", "days").format("MMMM Do, YYYY");

        Swal.fire({
          text:
            "Your 30-day mission to protect the SOL Space ends on " +
            day +
            ". To abort the mission, please pay the 499 $VAULT and abort the mission(Unstake).",
          confirmButtonText: "Abort the mission",
          customClass: {
            confirmButton: "confirm-abort-btn",
            cancelButton: "cancel-abort-btn",
          },
          showCancelButton: true,
          // reverseButtons:true
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            PayAndUnstakeToken(address);
            console.log("paying");
          }
        });
        return;
      } else if (daydiff < 72) {
        Swal.fire({
          text: "The minimum staking period is 3 days and your accumulated rewards can be withdrawn and Unstake NFT only after 72 hours.",
          confirmButtonText: "Close",
        });
        return;
      }
      if (!publicKey) return;
      isloading(true);
      const unstakeRes = await sendAndConfirmTransaction(
        await createUnstakeTokenTransaction(connection, publicKey, address),
        address
      );
      if (unStakeFlag && unstakeRes) {
        unStakeFlag(true);
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  const PayAndUnstakeToken = useCallback(
    async (address: PublicKey) => {
      if (!publicKey) return;
      isloading(true);
      const payUnstakeRes = await sendAndConfirmTransaction(
        await createPayAndUnstakeTokenTransaction(
          connection,
          publicKey,
          address
        )
      );
      if (payUnstakeRes) {
        Swal.fire({
          text: "You have Successfully Withdrawn Rewards and Unstaked Your NFT!",
          confirmButtonText: "Close",
        });
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  const withdrawRewards = useCallback(
    async (address: PublicKey, daydiff: Number) => {
      if (daydiff < 72) {
        Swal.fire({
          text: "The minimum staking period is 3 days and your accumulated rewards can be withdrawn only after 72 hours.",
          confirmButtonText: "Close",
        });
        return;
      }
      if (!publicKey) return;
      isloading(true);
      const withDrawres = await sendAndConfirmTransaction(
        await createWithdrawRewardTransaction(connection, publicKey, address)
      );
      if (withDrawRewardFlag && withDrawres) {
        withDrawRewardFlag(true);
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  const updateToken = useCallback(
    async (address: PublicKey) => {
      if (!publicKey) return;
      isloading(true);
      const upgraderes = await sendAndConfirmTransaction(
        await createUpdateTokenTransaction(
          connection,
          publicKey,
          address,
          is_upgradable
        )
      );
      if (upgraderes) {
        Swal.fire({
          text: "Your GUARD upgrade was successful, and you're now part of a 30-day mission to protect the SOL Space.",
          confirmButtonText: "Share on twitter",
          customClass: {
            confirmButton: "twitter-share-btn",
            cancelButton: "cancel-btn",
          },
          showCancelButton: true,
          reverseButtons: true,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            window.open(
              "https://twitter.com/intent/tweet?text=I have just upgraded my @thevaultdao Guard and Joined the mission to protect the %23SOL space.%0a %0aCheck it out: https%3A%2F%2Fdiscord.gg%2FCaSVfkCbvc %0a %0aFeeling &hashtags=Vaultish,Solana,escrow,NFTs",
              "_blank"
            );
            console.log("paying");
          }
        });

        // Swal.fire({
        //   text: "Your GUARD upgrade was successful, and you're now part of a 30-day mission to protect space.",
        //   confirmButtonText: "Close",
        // });
      }
      // if (withDrawRewardFlag && withDrawres) {
      //   withDrawRewardFlag(true);
      // }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  return (
    <Card>
      <Card.Body>
        {"locking_timestamp" in nft && nft["locking_timestamp"] > 0 ? (
          <div className="in-misssion">In Mission</div>
        ) : (
          ""
        )}
        <img
          className="nft-img"
          src={nft.json.image}
          alt={nft.json.description}
        />
        <h3 className={staked ? "nft-nameStaked" : "nft-nameUnstaked"}>
          {nft.data.name}
        </h3>
        {staked ? (
          <>
            <div className="rewards">
              Rewards:{" "}
              <span className="counter">
                {calculateRewards(
                  nft as StakedData,
                  rewardDate,
                  rewardCalculator
                )}
              </span>{" "}
              $VAULT
            </div>
            <hr className="horizontal-line" />
            <h3 className="rewards">{nft.json.attributes[0].value}</h3>
            <Button
              className="stake"
              onClick={() => withdrawRewards(nft.mint, nft["t"])}
            >
              Withdraw
            </Button>
            <Button
              className="stake"
              onClick={() =>
                unstakeToken(
                  nft.mint,
                  nft["t"],
                  nft["art_upgated_locking_period"],
                  nft["locking_timestamp"]
                )
              }
            >
              Withdraw & Unstake
            </Button>
            {nft.data.creators.length === 2 && (
              <Button className="upgrade" onClick={() => updateToken(nft.mint)}>
                Guard the Space
              </Button>
            )}
          </>
        ) : (
          <div style={{ paddingTop: "10px" }}>
            <Button className="stake" onClick={() => stakeToken(nft.mint)}>
              Select
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
